/*
adapted from: Allevasoft\Scripts\pageScripts\Services\allevaAutosave.service.js
*/

// SiteStyles.$inject = ['$timeout', 'logService','$localStorage','allevaApi'];
// export default function SiteStyles($timeout, logService, $localStorage, allevaApi) {

AllevaAutosave.$inject = ['$timeout', '$interval', '$rootScope'];
export default function AllevaAutosave($timeout, $interval, $rootScope) {

    /**
     * There are two different ways to utilize Autosave:
     *  1. TIMER - The first way is to use straight up timer intervals (autosave will repeat every x seconds).
     *              Used by calling allevaAutosave.start() -- DEFAULT
     *  2. TRIGGER - The second way is triggering autosave by activity (autosave will trigger after activity has been captured from user)
     *              Used by calling allevaAutosave.trigger(). 
     */


    /**
     *                      Use Case EXAMPLES:
     * ******************************************************************************
     * 
     * //Example 1: Autosave with DEFAULT configurations (using TIMER)
     * allevaAutosave.start(); 
     * // ALTERNATIVELY: allevaAutosave.init(null, true);
     * 
     * ******************************************************************************
     * 
     * //Example 2: Autosave with CUSTOM configurations (using TIMER)
     * allevaAutosave.init(
     *     {
     *          delay: 5000 //set delay to 5 senconds
     *          count: 0 // set interval to trigger indefinite
     *     }, true // true here for AUTO START
     *  );
     * 
     * ******************************************************************************
     * 
     * * //Example 3: Autosave with DEFAULT configurations (using TRIGGER)
     * allevaAutosave.trigger(); // should be called or mapped to on-change events
     * 
     * ******************************************************************************
     * 
     * //Example 4: Autosave with CUSTOM configurations (using TRIGGER)
     * allevaAutosave.init(
     *     {
     *          delay: 2000 //set delay to 2 senconds
     *          maxDelay: 10000 // Set maximum delay between saves to 10 seconds
     *     }
     *  );
     * // After initializing with configs, call:
     * allevaAutosave.trigger(); // should be called or mapped to on-change events
     * 
     * ******************************************************************************
     */

    /***************************
     * Properties
     **************************/
    // Configuration Defaults
    var DEFAULT_DELAY     = 5000;  // Interval to autosave in ms. Meant to reset after user input. DEFAULT 5 seconds.
    var DEFAULT_MAX_DELAY = 15000  // Max interval between saves. Only used for TRIGGER. DEFAULT 15 seconds.
    var DEFAULT_COUNT     = 0      // Number of times to repeat. Only used for TIMER. DEFAULT 0 (indefinite).

    // Flags
    var fromTrigger   = false;  // Used when autosaving by user triggers/ activity. Autosave is only shot off once, then gets disabled afterwards.
    var canResetTimer = true;   // A performance consideration -- Allows for $interval to only be triggered once every SPAM_INTERVAL

    // Constants
    const SPAM_INTERVAL = 200;  // A performance consideration -- Interval buffer in ms (curtails spammability of $interval)

    // Timer Stuff
    var timer       = null;  // The $interval angularjs promise
    var elapsedTime = 0;     // Keeps track of how long the last autosave was. Only used for TRIGGER.

    // Configuration Model
    var configuration = {
        delay   : DEFAULT_DELAY,
        maxDelay: DEFAULT_MAX_DELAY,
        count   : DEFAULT_COUNT 
    }

    /***************************
     * Init
     **************************/
    this.init = (options = null, autoStart = false) => {

        // Override default configurations
        configuration = Object.assign({}, configuration, options);

        if(autoStart){
            this.start();
        }
    }

    /***************************
     * Init (TIMER)
     **************************/
    this.start = () => {
        // Set flags
        fromTrigger = false;

        // Start timers
        startTimer();
    }

    /***************************
     * Init (TRIGGER)
     **************************/
    // Use trigger to 'wake' an autosave from an on-change event. Starts/ resets timer for autosaves. 
    this.trigger = () => {

        // Set flags
        fromTrigger = true;

        // Reset/ Start timers
        // Calculate eapsed and total time of triggers
        const now = new Date().getTime();
        elapsedTime = elapsedTime == 0 ? now : elapsedTime;
        const totalTime = now - elapsedTime;

        // Only reset timer if elapsed time is less than the MAXIMUM delay allowed.
        if(totalTime < configuration.maxDelay){
            resetTimer();
        }
        
    }

    /***************************
     * Timer
     **************************/
    const startTimer = () => {
        timer = $interval(function () {

            // Reset elapsedTime
            elapsedTime = 0;

            // Broadcast Autosave
            $rootScope.$broadcast("AllevaAutosave.autosave");


        }, configuration.delay, fromTrigger ? 1 : configuration.count);
    }

    const resetTimer = () => {

        // User performed trigger (e.g. typing) -- resets the timer
        $interval.cancel(timer);

        if (canResetTimer) { // Allows for this to only be triggered once every SPAM_INTERVAL seconds
            canResetTimer = false;
            $timeout(function () {
                canResetTimer = true;
                startTimer();
            }, SPAM_INTERVAL);
        }

    };

    /***************************
     * Event Handlers
     **************************/
    // Same as this.trigger, but can be triggered with rootScope. 
    $rootScope.$on("AllevaAutosave.trigger", () => { 
        this.trigger();
    });
}

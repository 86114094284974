MyApplicationsController.$inject = ['logService', '$scope', 'allevaApi', '$timeout', 'noty', '$interval'];
export default function MyApplicationsController(logService, $scope, allevaApi, $timeout, noty, $interval) {
    /***************************
     * Params
     **************************/
    // Page
    $scope.setPageTitle("Alleva Family - My Applications");

    // UI
    $scope.showApplicationCard = false;
    $scope.showApplicationForm = false;
    $scope.showCustomForm      = false;
    $scope.applicationsLoaded  = false;

    $scope.pristinePage = true;
    $scope.isLoading    = false;

    // Data
    $scope.application   = null;
    $scope.countries     = null;
    $scope.relationships = null;
    $scope.contactList   = [];

    $scope.currentForm      = null;
    $scope.currentApplicant = null;

    // Table Pagination
    $scope.paginateApplication = {};
    $scope.paginateApproval    = {};
    $scope.paginateDischarge   = {};

    // Constants
    const _FamilyFormSectionId                  = 9;
          $scope._FamilyFormTypeUponApplication = 2000;
          $scope._FamilyFormTypeUponAcceptance  = 2001;
          $scope._FamilyFormTypeUponDischarge   = 2002;

    /***************************
     * Init
     **************************/
    $scope.initMyApplications = () => {
        if($scope.applicationsLoaded) { return; }

        logService.console.log("My Forms");

        $scope.showApplicationCard = true;

        // Navigation
        $scope.activeNav($scope._PageMyApplication);

        $scope.resetApplication();

        if (!$scope.currentUser.clients.length) { // Force application page for users with no clients
            $scope.addApplicationClicked();
        }

        $scope
            .getFamilyForms({
                FacilityId : $scope.currentUser.facilityId,
                WithLayout : true,
                RehabUserId: $scope.currentUser.rehabUserId
            })
            .then(data => {
                logService.console.info("FamilyForms", data);

                var applicationForms = [];
                data.forEach(form => {
                    if(form.familyFormType == $scope._FamilyFormTypeUponApplication){
                        applicationForms.push(form);
                    }
                });

                $scope.checkList             = applicationForms;
                $scope.familyChecklistLoaded = true;
                $scope.applicationsLoaded    = true;
            })
            .catch(error => {
                logService.console.error("Error");
                $scope.familyChecklistLoaded = true;
            });
    };

    $scope.initPrimaryContact = () => {
        $scope.application.primaryContact.fullName = $scope.currentUser.firstName + ' ' + $scope.currentUser.lastName;
        $scope.application.primaryContact.email    = $scope.currentUser.email;
        $scope.application.primaryContact.isSelf   = true;
    };

    /***************************
     * Actions
     **************************/
    $scope.validateSubmit = (form) => {
        if(!form.$valid){
            let alert = "<h5>Incomplete Application</h5><p>Please fill out all required fields.</p>";
            new noty({
            text: alert
            }).show();
            return false;
        }
        return true;
    };

    $scope.submitAlert = (date) => {

        // BYPASS alert for now

        // BootstrapDialog.confirm({
        //     title     : 'Submit Patient',
        //     message   : 'You are about to submit <b class="underline">' + $scope.application.firstName + ' ' + $scope.application.lastName + '</b> for approval. Continue?',
        //     type      : BootstrapDialog.TYPE_WARNING,                                                                                                                          // <-- Default value is BootstrapDialog.TYPE_PRIMARY
        //     closable  : true,                                                                                                                                                  // <-- Default value is false
        //     btnOKLabel: 'Yes, Submit',                                                                                                                                         // <-- Default value is 'OK',
        //     btnOKClass: 'btn btn-primary',                                                                                                                                     // <-- If you didn't specify it, dialog type will be used,
        //     callback  : function(submitted) {
        //         if (submitted) {
        //             $scope.isLoading = true;

        //             // Setup contacts 
        //             $scope.application.contacts = [];
        //             $scope.application.contacts.push($scope.application.primaryContact);
        //             $scope.application.contacts = $scope.application.contacts.concat($scope.contactList);
        //             $scope.createProspect()
        //                 .then(prospect => {
                            
        //                     logService.console.info("Prospect", prospect);

        //                     $scope.getCurrentUserDetails((success) => {
                                
        //                         // if there are application forms, start them else go back to list view
        //                         var currentClient = null;
        //                         $scope.currentUser.clients.forEach(client => {
        //                             if(client.leadId == prospect.leadId){
        //                                 currentClient = client;
        //                             }
        //                         });

        //                         if(currentClient.checklist.applicationChecklist.checklistPending.length){
        //                             $scope.isLoading = false;
        //                             $scope.startFormClicked(currentClient, currentClient.checklist.applicationChecklist.checklistPending[0], true);
        //                         }
        //                         else{
        //                             $scope.isLoading = false;
        //                             $scope.closeApplicationClicked();
        //                         }
        //                     }, true);
        //                 })
        //                 .catch(error => {
        //                     $scope.isLoading = false;
        //                     logService.console.error(error);
        //                 });
        //         }
        //     }
        // });

        $scope.isLoading = true;

        // Setup contacts 
        $scope.application.contacts = [];
        $scope.application.contacts.push($scope.application.primaryContact);
        $scope.application.contacts = $scope.application.contacts.concat($scope.contactList);
        $scope.createProspect()
            .then(prospect => {
                
                logService.console.info("Prospect", prospect);

                $scope.getCurrentUserDetails((success) => {
                    
                    // if there are application forms, start them else go back to list view
                    var currentClient = null;
                    $scope.currentUser.clients.forEach(client => {
                        if(client.leadId == prospect.leadId){
                            currentClient = client;
                        }
                    });

                    if(currentClient.checklist.applicationChecklist.checklistPending.length){
                        $scope.isLoading = false;
                        $scope.startFormClicked(currentClient, currentClient.checklist.applicationChecklist.checklistPending[0], true);
                    }
                    else{
                        $scope.isLoading = false;
                        $scope.closeApplicationClicked();
                    }
                }, true);
            })
            .catch(error => {
                $scope.isLoading = false;
                logService.console.error(error);
            });
    };

    $scope.addApplicationClicked = () => {
        $scope.initPrimaryContact();
        
        if($scope.isClientRole($scope.currentUser.roleObj)){
            // Autofill fields
            $scope.application.firstName = $scope.currentUser.firstName;
            $scope.application.lastName = $scope.currentUser.lastName;
            $scope.application.email    = $scope.currentUser.email;
        }

        if (!$scope.countries) {
            $scope.getCountries().then(countries => {
                logService.console.info("Countries", countries);
                $scope.countries = countries;
            });
        }

        if (!$scope.relationships) {
            $scope.getRelationships()
                .then(relationships => {
                    logService.console.info("relationships", relationships);
                    $scope.relationships = relationships;
                });
        }


        $scope.showApplicationCard = false;
        $scope.showApplicationForm = true;
        $scope.showCustomForm      = false;

    };

    $scope.closeApplicationClicked = (timer = null) => {
        if(timer){
            $interval.cancel(timer);
            $scope.showCustomForm = false;
            $scope.isLoading = true;
            $scope.getCurrentUserDetails((success) => {
                $scope.isLoading = false;
                $scope.showApplicationCard = true;
                $scope.showApplicationForm = false;
                $scope.showCustomForm      = false;
                $scope.showCustomFormView  = false;

                $scope.resetApplication();
            });
        }
        else{
            $scope.showApplicationCard = true;
            $scope.showApplicationForm = false;
            $scope.showCustomForm      = false;
            $scope.showCustomFormView  = false;

            $scope.resetApplication();
        }
        $scope.$broadcast("AdvancedForm.stopAutosaveTimer");
    };

    $scope.viewFormClicked = (client, form) => {
        $scope.currentForm      = form;
        $scope.currentApplicant = client;

        $scope.showApplicationCard = false;
        $scope.showApplicationForm = false;
        $scope.showCustomForm      = false;
        $scope.showCustomFormView  = true;
    };

    $scope.startFormClicked = (client, form, isApplication = false) => {
        $scope.currentForm                    = form;
        $scope.currentForm.saveForLater       = false;
        $scope.currentForm.saveFromAutosave   = false;
        $scope.currentForm.formSubmitted      = $scope.formDidFinishSubmitting;
        $scope.currentApplicant               = client;
        $scope.currentApplicant.isApplication = isApplication;

        $scope.showApplicationCard = false;
        $scope.showApplicationForm = false;
        $scope.showCustomForm      = true;
        $scope.showCustomFormView  = false;
    };

    $scope.countrySelected = () => {
        $scope
            .getStates({
                countryId: $scope.application.country.countryId
            })
            .then(states => {
                logService.console.info("states", states);
                $scope.states = states;
            });
    };

    $scope.addContact = () => {
        var newcontact = {Id: Math.floor(Math.random() * 100000) + 1};
        $scope.contactList.push(newcontact);
    };

    $scope.removeContact = (contactToRemove) => {
        let tempContactList    = $scope.contactList.filter(contact => contact.Id != contactToRemove.Id);
            $scope.contactList = tempContactList;
    };

    /***************************
     * Custom Form Callbacks
     **************************/
    $scope.formDidFinishSubmitting = (form = null) => {
        
        // From Autosave
        if($scope.currentForm.saveFromAutosave) { 
            $scope.currentForm.saveFromAutosave = false; 

            $scope.moveItemToInProgress();

            let alert = "<h5>Autosave complete</h5><p>You form has been successfully autosaved.</p>";
            new noty({
            text: alert,
            type: 'success'
            }).show();
            return; 
        }

        $scope.showCustomForm = false;

        $scope.isLoading = true;

        // Remove completed from from pending list
        $scope.currentApplicant.checklist.applicationChecklist.checklistPending = $scope.currentApplicant.checklist.applicationChecklist.checklistPending.filter(item => item.checklistItemId != $scope.currentForm.checklistItemId);

        // Add completed form to the completed list
        $scope.currentApplicant.checklist.applicationChecklist.checklistCompleted.push($scope.currentForm);

        // Get next form if any
        let nextForm = $scope.currentApplicant.checklist.applicationChecklist.checklistPending[0];
        if(nextForm && $scope.currentApplicant.isApplication){
            $scope.getCurrentUserDetails();

            $timeout(() => {
                $scope.startFormClicked($scope.currentApplicant, nextForm, $scope.currentApplicant.isApplication);
                $scope.isLoading = false;

            }, 500);   
        }
        else{
            $scope.getCurrentUserDetails((success) => {
                $scope.isLoading = false;
                $scope.closeApplicationClicked();
            });
        }
    };

    $scope.moveItemToInProgress = () => {
        Object.keys($scope.currentApplicant.checklist).forEach(checklistType => {
            const checklist = $scope.currentApplicant.checklist[checklistType];
    
            if (!checklist.checklistInProgress.some(item => item.checklistItemId === $scope.currentForm.checklistItemId) &&
                checklist.checklistPending.some(item => item.checklistItemId === $scope.currentForm.checklistItemId)) {
                checklist.checklistInProgress.push($scope.currentForm);
                checklist.checklistPending = checklist.checklistPending.filter(item => item.checklistItemId !== $scope.currentForm.checklistItemId);
            }
        });
    };
    

    /***************************
     * CRUD
     **************************/
    // GET
    $scope.getFamilyForms = filter => {
        return allevaApi.FamilyAPI.getForms(filter)
            .then(result => {
                return Promise.resolve(result);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    };

    // POST
    $scope.createProspect = () => {
        return allevaApi.FamilyAPI.createProspect($scope.application)
            .then(result => {
                return Promise.resolve(result);
            })
            .catch(error => {
                return Promise.reject(error);
            });
    };
    
    /***************************
     * Helpers
     **************************/
    $scope.getApprovedClients = () => {
        var clients = [];
        $scope.currentUser.clients.forEach(client => {
            if(client.isClient || client.isApproved){
                clients.push(client);
            }
        });
        $scope.approvedClients = clients;
    };

    $scope.getDischargedClients = () => {
        var clients = [];
        $scope.currentUser.clients.forEach(client => {
            if(client.isDischarged){
                clients.push(client);
            }
        });
        $scope.dischargedClients = clients;
    };

    $scope.expandApplicationRow = (client) => {
        $scope.pristineApplicationPage = false;
        client.expandedApplication     = !client.expandedApplication;
    };

    $scope.expandApprovedRow = (client) => {
        $scope.pristineApprovedPage = false;
        client.expandedApproved     = !client.expandedApproved;
    };

    $scope.expandDischargeRow = (client) => {
        $scope.pristineDischargePage = false;
        client.expandedDischarge     = !client.expandedDischarge;
    };

    $scope.resetApplication = () => {
        $scope.application = {
            primaryContact: {},
            gender        : $scope._GenderCodeNoPref,
            facilityId    : $scope.currentUser.facilityId,
            rehabId       : $scope.currentUser.rehabId,
            rehabUserId   : $scope.currentUser.rehabUserId
        };
    };
}

import './jSignature';

Signature.$inject = [
    '$compile',
    '$element'];

export default function Signature($compile,$element) {
    var ctrl = this;
    var targetElement = null;
    var targetElementWrapped = null;

    ctrl.updateSetting = function (setting, value) {
        if (ctrl.initialized)
            targetElementWrapped.jSignature('updateSetting', setting, value);
    }

    ctrl.$onChanges = function (changes) {
        for (var key in changes) {

            // hacky
            if (key == 'init') {
                if (changes[key].currentValue)
                    ctrl.initialize();
            }
            if (key == 'bgColor' && ctrl.initialized) {
                var newkey = 'background-color';
                ctrl.updateSetting(newkey, changes[key].currentValue);
                options[newkey] = changes[key].currentValue;

                var e = targetElement.querySelector('.jSignature')
                $(e).css('background-color', changes[key].currentValue);

            }
            else if (ctrl.initialized) {
                ctrl.updateSetting(key, changes[key].currentValue);
                options[key] = changes[key].currentValue;
            }

        }
    }

    var options = {
        width: '100%',//ctrl.width,
        height: '100%',//ctrl.height,
        color: ctrl.color,
        'background-color': ctrl.bgColor,
        lineWidth: ctrl.lineWidth,
        cssClass: ctrl.cssClass
    };

    ctrl.$onInit = function () {
        targetElement = $element[0].querySelector('.signature-canvas-target');
                targetElementWrapped = angular.element(targetElement);
                targetElementWrapped.jSignature(options);
                ctrl.blankData = targetElementWrapped.jSignature('getData');
                ctrl.initialized = true;
    };

    // TODO: Use $onInit
    ctrl.initialize = function () {
        
    };

    ctrl.reset = function () {
        targetElementWrapped.jSignature(options);
    };

    ctrl.getData = function () {
        //var datapair = $element[0].firstChild..jSignature('getData', 'image/png;base64');
        var datapair = targetElementWrapped.jSignature('getData', 'image/png;base64');
        console.dir(datapair);

    };

    ctrl.setData = function (sig) {
        console.log('setData');
        var datapair = ['image/png;base64'];

        // 'image/png;base64,iVBORw0KG...'
        datapair[1] = sig;

        targetElementWrapped.jSignature('setData', 'data:' + datapair.join(','));

    };

    ctrl.hide = function () {
        ctrl.show = false;
    };

    ctrl.clearSignatureCanvas = function () {
        targetElementWrapped.jSignature('clear');
    }

};


